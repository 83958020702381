import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Enquiry from "./pages/Enquiry";
import { useState } from "react";
import About from "./pages/About";
import Services from "./pages/Services";

function App() {

  const [flag, setFlag] = useState(false)

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <Routes>
      <Route path="/" element={<Home setFlag={setFlag} scrollToSection={scrollToSection} />} />
      <Route path="/about" element={<About scrollToSection={scrollToSection} />} />
      <Route path="/services" element={<Services scrollToSection={scrollToSection} />} />
      <Route path="/enquiry" element={<Enquiry flag={flag} setFlag={setFlag} />} />
    </Routes>
  );
}

export default App;
