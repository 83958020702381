import React from "react";
import {
  contractImageOne,
  contractImageThree,
  contractImageTwo,
} from "../assets/images";

const Contract = () => {
  return (
    <>
      <div className="contractWrapper">
        <h2 className="heading">Government Contracts</h2>
        <div className="contractImageWrapperMob">
          <div className="contractImgHoverMob">
            <img src={contractImageThree} alt="contractImageThree" />
            <div className="contractDivHover">
              <h3>Aanchal Dairy (Uttarakhand Govt)</h3>
            </div>
          </div>
          <div className="contractImgHoverMob">
            <img src={contractImageOne} alt="contractImageTwo" />
            <div className="contractDivHover">
              <h3>Uttarafish : Fisheries Department</h3>
            </div>
          </div>
          <div className="contractImgHoverMob">
            <img src={contractImageTwo} alt="contractImageOne" />
            <div className="contractDivHover">
              <h3>Bakraw : Animal Husbandry (Uttarakhand Govt)</h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contract;
