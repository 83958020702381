import React from 'react'
import Background from "../components/Background";
import Project from '../components/Project';
import NavBar from "../components/NavBar";
import { clientReviewImg1, techImg } from "../assets/images";

const About = ({ scrollToSection }) => {
    return (
        <main className="wrapper">
            <div id="home" className="homeWrapper">
                <section>
                    <Background />
                </section>
                <section>
                    <NavBar scrollToSection={scrollToSection} />
                </section>
                <section className="story-section">
                    <div className="content">
                        <h1>The PieWebServices Story</h1>
                        <p>
                            At Piewebservices, we are equipped with all the modern technological and digital know-how
                            which could provide the most pointed solutions to our clients. Whatever the challenges,
                            we know how to adapt to all the changing circumstances and mould ourselves to suit the same.
                            We believe in being flexible with our working techniques, without straying an inch away from
                            delivering all requirements.
                        </p>
                        <p>
                            We are passionate about supporting other businesses in their growth. All our clients need to do
                            is focus on their core business while we handle everything else to help them stay ahead.
                        </p>
                    </div>
                    <div className="image">
                        <img src={techImg} alt="story" />
                    </div>
                </section>
                <div className="valuesWrapper">
                    <h2 className="heading">Our Values</h2>
                    <p>Our business plan based on strong human values that drive us on adaily basis.</p>
                    <div className="projectMiddle">
                        <div>
                            <h3>Confidence</h3>
                            <p>At Piewebservices, trust is paramount. The relationship with each of our clients is unique, because we do not keep any information and regularly communicate on the methods used and the results obtained.</p>
                        </div>
                        <div>
                            <h3>Expertise</h3>
                            <p>Rigorous, conscientious, our expertise directs all our missions, both in the performance of our actions and in the reliability of our efforts.</p>
                        </div>
                        <div>
                            <h3>Passion</h3>
                            <p>We are convinced that it is impossible to exercise our profession with our level of requirement without passion and pleasure. The joy of getting up in the morning, collaborating with our customers and the well-being of working as a team are our daily drivers.</p>
                        </div>
                        <div>
                            <h3>Learning</h3>
                            <p>Digital is a sector in perpetual motion and the truth of a day is not necessarily that of tomorrow. Passionate about our job, we constantly strive for excellence and this requires continuous learning</p>
                        </div>
                        <div>
                            <h3>Humility</h3>
                            <p>We consider that humility is a fundamental value to improve. As the lean management guru, Taiichi Ohno, said, "no one has more big problems than one who says he does not have any."</p>
                        </div>
                        <div>
                            <h3>Curiosity</h3>
                            <p>Eager to learn and test the new digital codes, we are always excited to discover the latest web marketing trends. This curiosity is also what makes our expertise.</p>
                        </div>
                    </div>
                </div>
                <section className="team-section">
                    <h2 className='heading'>We wouldn't be here without them</h2>
                    <div className="team-container">
                        <div className="team-member">
                            <img src={clientReviewImg1} alt="img1" />
                            <h3>Saugata</h3>
                            <p>Founder & CEO</p>
                        </div>
                        <div className="team-member">
                            <img src={clientReviewImg1} alt="img2" />
                            <h3>Saugata</h3>
                            <p>Founder & CEO</p>
                        </div>
                        <div className="team-member">
                            <img src={clientReviewImg1} alt="img3" />
                            <h3>Saugata</h3>
                            <p>Founder & CEO</p>
                        </div>
                        <div className="team-member">
                            <img src={clientReviewImg1} alt="img4" />
                            <h3>Saugata</h3>
                            <p>Founder & CEO</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Project scrollToSection={scrollToSection} />
                </section>
            </div>
            <footer className="homeFooter">
                Copyright © 2024 Piewebservices. All Rights Reserved.
            </footer>
        </main>
    )
}

export default About
