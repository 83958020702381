import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Experience = ({ scrollToSection }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname === '/') {
      scrollToSection("form")
    } else {
      navigate(`/enquiry`)
    }
  }

  return (
    <div id="about" className="experienceWrapper">
      <div className="experienceLeft">
        <h2>7+</h2>
        <p>Years of Experience</p>
      </div>
      <div className="experienceRight">
        <h4 className="heading">We have been doing this for</h4>
        <div>
          <span className="heading">more than 7 years.</span>
        </div>
        <p className="para">
          We have been doing Websites since 2017 and have delivered many successful projects. Some of our best works are mentioned below.
        </p>
        <h6>Get the project started. Book Now.</h6>
        <button onClick={handleClick} className="btn">Book Now</button>
      </div>
    </div>
  );
};

export default Experience;