import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const Forms = ({ setFlag }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [requirements, setRequirements] = useState(null);

  const analyticsEvent = ({ action, category, label, value }) => {
    if (typeof window.gtag === "function") {
      window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      });
    } else {
      console.error("gtag is not defined");
    }
  }

  const scriptURL =
    "https://api.sheetbest.com/sheets/4399bc0d-fdbc-4514-9f47-42c579dbc912";

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name || !email || !phone || !requirements) {
      alert("Fill the details below!")
      return
    }
    fetch(scriptURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        requirements: requirements,
      }),
    })
      .then((response) => {
        // For submit form analytics
        analyticsEvent({
          action: "submitted the form",
          category: "business",
          label: "Submitted the form",
          value: "PieWeb",
        });
        setFlag(true)
        navigate("/enquiry")
        console.log("Success!", response);
      })
      .catch((error) => console.error("Error!", error.message));
  };

  return (
    <>
      {!location.pathname.includes('/enquiry') && <h5>Get a call back in 30 mins.</h5>}
      {!location.pathname.includes('/enquiry') && <p>Share your Requirements or WhatsApp me to get a call back.</p>}
      {location.pathname.includes('/enquiry') && <h5>Hello, Welcome to Piewebservices!</h5>}
      {location.pathname.includes('/enquiry') && <p>Please share your requirements</p>}
      <form name="contact-us" onSubmit={submitHandler}>
        <input
          onChange={(e) => setName(e.target.value)}
          type="text"
          name="name"
          placeholder="Enter your name"
        />
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          name="email"
          placeholder="Enter your email"
        />
        <div>
          <span>+91</span>
          <input
            onChange={(e) => setPhone(e.target.value)}
            type="text"
            className="phone"
            name="phone"
            placeholder="Enter your phone no."
          />
        </div>
        <textarea
          onChange={(e) => setRequirements(e.target.value)}
          name="requirements"
          rows={5}
          placeholder="Describe your requirement"
        ></textarea>
        <button className="btn" type="submit">
          {!location.pathname.includes('/enquiry') ? 'Get a Call Back' : 'Submit'}
        </button>
      </form>
    </>
  );
};

export default Forms;
