import React from 'react'
import Clients from "../components/Clients";
import Background from "../components/Background";
import Technologies from '../components/Technologies';
import NavBar from "../components/NavBar";

const Services = ({ scrollToSection }) => {
    return (
        <main className="wrapper">
            <div id="home" className="homeWrapper">
                <section>
                    <Background />
                </section>
                <section>
                    <NavBar scrollToSection={scrollToSection} />
                </section>
                <section className='services'>
                    <Clients />
                </section>
                <section>
                    <Technologies />
                </section>
            </div>
            <footer className="homeFooter">
                Copyright © 2024 Piewebservices. All Rights Reserved.
            </footer>
        </main>
    )
}

export default Services
